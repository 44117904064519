/* Theme colors
#3F826D 
#E84855
#161F51
#BDD5EA
#EBECED
 */

$themeGreen: #3f826d;
$themePink: #e84855;
$themeNavy: #161f51;
$themeLightBlue: #bdd5ea;
$themeOffWhite: #ebeced;
$themeGreenHeader: rgba(63, 130, 109, 0.9);
$themePinkHeader: rgba(232, 72, 85, 0.9);
$themeNavyHeader: rgba(22, 31, 81, 0.9);
$themeLightBlueHeader: rgba(189, 213, 234, 0.9);
$themeOffWhiteHeader: rgba(235, 236, 237, 0.9);

@font-face {
  font-family: "BaiJamjuree-Regular";
  src: local("BaiJamjuree-Regular"),
    url(./fonts/Bai_Jamjuree/BaiJamjuree-Regular.woff2) format("woff2");
}

html {
  font-size: 20px;
  min-width: 375px;
}
section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.App {
  background-color: $themeOffWhite;
  font-family: "Raleway", sans-serif;
}

.Header-Background {
  background-image: url(./images/code4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-attachment: scroll;
}

.App-Header {
  background: linear-gradient(327deg, $themeGreenHeader, $themeNavyHeader);

  background-size: 400% 400%;

  -webkit-animation: App 20s ease infinite;
  -moz-animation: App 20s ease infinite;
  animation: App 20s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Header-Box {
  border: solid 2px white;
  font-size: 2em;
  padding: 2%;
  font-family: "BaiJamjuree-Regular";
  text-align: center;
}

.Section-Titles {
  border: solid 2px $themePink;
  font-size: 2em;
  padding: 15px;
  font-family: "Advent Pro", sans-serif;
  margin: 1rem 0 1rem 0;
  color: $themePink;
  white-space: nowrap;
  display: inline-block;
}

.Header-Box span {
  font-weight: bold;

  color: $themePink;
}

.Blue-Circles {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  background-color: $themeNavy;
  border: 1px solid grey;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.White-Circles {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  border: 1px solid grey;
  width: 15px;
  height: 15px;
}

.Skill-Label {
  display: inline;
  font-size: 1em;
  font-family: "Krub", sans-serif;
  margin-left: 5px;
  font-weight: bold;
}

.Skills-Icon {
  position: relative;
  color: $themeGreen;
  font-size: 4em;
}

.Skill-Component {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Off-Section {
  background-color: white;
}

.Soft-Skills-Card {
  height: 275px;
  margin-top: 20px;
}
.card-title {
  text-align: center;
}
.card-body > h3 {
  font-size: 1em;
  font-weight: bold;
}

.card-text {
  font-size: 0.8em;
}
.card {
  box-shadow: 5px 7px 10px #c4c4c4;
}
.portfolioCard {
  height: 500px;
  width: 400px;
  margin-top: 2rem;
}
.portfolioCard a {
  text-decoration: none;
  cursor: pointer;
}
.portfolioAnimatedDiv {
  width: fit-content;
  margin: auto;
}
.card:hover {
  box-shadow: none;
}
.Education-Component {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Education-Icon {
  float: left;
}

.Education-Description {
  margin-left: 20px;
  float: left;
}
.linkedinIcon svg path{
  fill: #0077B5;
}
@media screen and (max-width: 1200px) {
  .Skill-Label {
    font-size: 0.8em;
    display: block !important;
  }
}
@media screen and (max-width: 1000px) {
  .Education-Component {
    text-align: center;
  }
  .Education-Icon {
    float: none;
  }

  .Education-Description {
    float: none;
    margin: auto !important;
  }
}

@media screen and (max-width: 767.98px) {
  .Skill-Component {
    text-align: center;
  }
  .portfolioCard {
    width: 350px;
  }
}
@media screen and (max-width: 600px) {
  .Header-Box {
    font-size: 6vw;
  }
}

@-webkit-keyframes App {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes App {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes App {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

$link-color: black;
@import "~bootstrap/scss/bootstrap";

.card-columns {
  @include media-breakpoint-only(xs) {
    column-count: 1;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
}
